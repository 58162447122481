import React from "react";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import 'bootstrap';
import './App.css';
import Home from "./pages/Home";
import Movie from "./pages/Movie";

function App() {
    return (
        <BrowserRouter>
            <div className="navbar navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand" to="/">Kinobe</Link>
                </div>
            </div>

            <Switch>
                <Route path="/" exact={true} component={Home}/>
                <Route path="/movie/:id" exact={true} component={Movie}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
