import React, {useEffect, useState} from 'react';
import Config from "../../Config";
import {Link} from "react-router-dom";

const Upcoming = () => {
    document.title = "Новинки кино";
    const [data, setData] = useState(null);
    const [, setError] = useState(null);
    const [, setIsLoading] = useState(true);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const basicError = "Request error. Please, try again.";
            try {
                const response = await fetch(`https://api.themoviedb.org/3/movie/upcoming?region=RU&api_key=${Config.ApiKey}&language=ru-RU`);
                const data = await response.json();
                setIsLoading(false);
                if (response.status === 200) {
                    setData(data);
                } else if (response.status !== 401) {
                    setError(basicError);
                }
            } catch (e) {
                setError("Check internet connection and try again.");
                setIsLoading(false);
            }
        })();
    }, [])

    return (
        <>
            <h2>Новинки</h2>
            {data !== null &&
            <div className="">
                {data.results.map((movie) => {
                    const releaseDate = new Date(movie.release_date);
                    const title = movie.title + (releaseDate !== null ? ` (` + releaseDate.getFullYear() + `)` : "");
                    const fullTitle = title + (movie.title !== movie.original_title ? ` / ${movie.original_title}` : "");
                    return <Link key={movie.id}
                                 className="d-flex float-start flex-column align-self-stretch p-2 me-1 text-decoration-none text-body"
                                 to={`/movie/${movie.id}`}
                                 title={fullTitle}>
                        <div style={{width: 140, height: 200}}>
                            <img className="img-fluid img-thumbnail mh-100" alt={fullTitle}
                                 src={"https://image.tmdb.org/t/p/w500" + movie.poster_path}/>
                        </div>
                        <h6 className="overflow-hidden mb-0" style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical'
                        }}>{title}</h6>
                        {movie.title !== movie.original_title &&
                        <div className="small text-muted overflow-hidden" style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical'
                        }}>{movie.original_title}</div>
                        }
                        {movie.vote_count > 0 &&
                        <div>{movie.vote_average}/10 ({movie.vote_count})</div>
                        }
                    </Link>
                })}
            </div>
            }
        </>
    );
};

export default Upcoming;
