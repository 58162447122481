import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Config from "../../Config";
import Credits from "./Credits";

const Movie = () => {
    const {id} = useParams();

    const [data, setData] = useState(null);
    const [, setError] = useState(null);
    const [, setIsLoading] = useState(true);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const basicError = "Request error. Please, try again.";
            try {
                const response = await fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${Config.ApiKey}&language=ru-RU`);
                const data = await response.json();
                setIsLoading(false);
                if (response.status === 200) {
                    setData(data);
                    document.title = data.title;
                } else if (response.status !== 401) {
                    setError(basicError);
                }
            } catch (e) {
                setError("Check internet connection and try again.");
                setIsLoading(false);
            }
        })();
    }, [id])
    return (
        <>
            {data !== null &&
            <div>
                <div className="py-4 mb-4" style={{
                    backgroundImage: `url("https://image.tmdb.org/t/p/w1920_and_h800_multi_faces` + data.backdrop_path + `")`,
                    // backgroundPosition: 'right -200px top',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className="container d-flex flex-row">
                        {data.poster_path !== null &&
                        <a href={"https://image.tmdb.org/t/p/w600_and_h900_bestv2" + data.poster_path} target="_blank"
                           className="me-4 w-25"
                           rel="noreferrer"><img className="img-fluid img-thumbnail mh-100" alt={data.title}
                                                 src={"https://image.tmdb.org/t/p/w300_and_h450_bestv2" + data.poster_path}/>
                        </a>
                        }
                        <div className="rounded w-75 p-4 align-self-start" style={{
                            backgroundColor: 'rgba(255,255,255,0.8)'
                        }}>
                            <h1>{data.title}</h1>
                            {data.runtime !== null &&
                            <div>
                                <strong>Длительность:</strong>
                                {Math.floor(data.runtime / 60)} ч. {data.runtime % 60} мин.
                            </div>
                            }
                            <div>
                                <strong>Жанры:</strong>
                                <ul>
                                    {data.genres.sort((a, b) => a.name > b.name ? 1 : -1).map((genre, i) =>
                                        <li key={i}>{genre.name}</li>
                                    )}
                                </ul>
                            </div>
                            {data.tagline !== null &&
                            <div className="fw-bold">{data.tagline}</div>
                            }
                            {data.overview !== null &&
                            <div>{data.overview}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="container my-3">
                    {data.budget > 0 &&
                    <div>
                        <strong>Бюджет:</strong>
                        ${new Intl.NumberFormat('ru-RU').format(data.budget)}
                    </div>
                    }
                    {data.revenue > 0 &&
                    <div>
                        <strong>Сборы:</strong>
                        ${new Intl.NumberFormat('ru-RU').format(data.revenue)}
                    </div>
                    }
                    <Credits/>
                </div>
            </div>
            }
        </>
    );
};

export default Movie;
