import React, {useEffect, useState} from 'react';
import Config from "../../Config";
import {useParams} from "react-router-dom";

const Credits = () => {
    const {id} = useParams();

    const [data, setData] = useState(null);
    const [, setError] = useState(null);
    const [, setIsLoading] = useState(true);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const basicError = "Request error. Please, try again.";
            try {
                const response = await fetch(`https://api.themoviedb.org/3/movie/${id}/credits?api_key=${Config.ApiKey}&language=ru-RU`);
                const data = await response.json();
                setIsLoading(false);
                if (response.status === 200) {
                    setData(data);
                } else if (response.status !== 401) {
                    setError(basicError);
                }
            } catch (e) {
                setError("Check internet connection and try again.");
                setIsLoading(false);
            }
        })();
    }, [id])

    return (
        <>
            <h3>В ролях</h3>
            {data !== null &&
            <div className="overflow-hidden">
                <div className="d-flex flex-row overflow-auto">
                    {data.cast.filter((a) => a.profile_path !== null).sort((a, b) => a.popularity > b.popularity ? -1 : 1).map((person, i) =>
                        <div className="me-2 d-flex flex-column" key={i}>
                            {person.profile_path !== null &&
                            <div style={{width: 140, height: 140}}>
                                <img className="img-fluid img-thumbnail mh-100" alt={person.name}
                                     src={"https://image.tmdb.org/t/p/w138_and_h175_face" + person.profile_path}/>
                            </div>
                            }
                            {person.name}
                            {person.character !== "" &&
                            <span className="small text-muted">{person.character}</span>
                            }
                        </div>
                    )}
                </div>
            </div>
            }
        </>
    );
};

export default Credits;
