import React from 'react';
import Upcoming from "./Upcoming";

const Home = () => {
    return (
        <div className="container">
            <Upcoming/>
        </div>
    );
};

export default Home;
